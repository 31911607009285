<template>
    <div class="card">
        <div class="cash-flow-header">
            <div class="cash-flow-title">Fluxo de caixa</div>
            <div class="actions">
                <Loading :class="{ reload: true, loading: loading }" @click="() => !loading && getCashFlowChart()"/>
                    <div class="select-box">
                        <CheckboxSelect
                            borderless
                            trackBy="id"
                            optionLabel="name"
                            label="Todas as contas"
                            v-model="bankAccountIds"
                            :options="bankAccounts.filter(el => el.id)"
                            @onChange="changeSelectedBankAccounts"
                            placeholder="Todas as contas"
                        />
                    </div>
                <DatePicker v-model="period" type="month" format="MMM [de] YYYY" />
            </div>
        </div>
        <div class="chart">
            <VueApexCharts
                :options="options"
                :series="series"
            />
        </div>
    </div>
  </template>
  <script>
  import api from '../api'
  import moment from 'moment'
  import { getCurrentClinic } from '@/utils/localStorageManager'
  import { parseNumberToMoney } from '@/utils/moneyHelper'

  export default {
    props: {
        bankAccounts: Array,
        height: {
            type: String,
            default: 'auto'
        }
    },
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        CheckboxSelect: () => import('@/components/CheckboxSelect'),
        DatePicker: () => import('@/components/General/DatePicker'),
        VueApexCharts: () => import('vue-apexcharts'),
    },
    created() {
      this.getCashFlowChart()
    },
    data() {
        const date = new Date()
        return {
            loading: false,
            clinic: getCurrentClinic(),
            bankAccountIds: [],
            period: [new Date(date.getFullYear(), date.getMonth() - 6, 1), new Date(date.getFullYear(), date.getMonth() + 6, 1)],
            series: [],
            options: {
                chart: {
                height: this.height,
                type: 'line',
                },
                forecastDataPoints: {
                count: 0
                },
                stroke: {
                width: 5,
                curve: 'smooth'
                },
                xaxis: {
                categories: [],
                tickAmount: 12,
                labels: {
                    formatter: function(value, timestamp, opts) {
                        return moment.utc(value).format('MMM YYYY')
                        // return opts.dateFormatter(new Date(timestamp), 'MMM YYY')
                    }
                }
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                        return parseNumberToMoney(val)
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0, 1],
                    formatter: function (val) {
                        return parseNumberToMoney(val)
                    },
                },
            },
        }
    },
    methods: {
        getCashFlowChart() {
            this.loading = true
            const bankAccountIds = this.bankAccountIds.map(el => el.id)
            api.getCashFlowChart(this.clinic.id, bankAccountIds, this.period[0], this.period[1])
                .then(({ data }) => {
                    this.series = data.series
                    this.options = {
                        ...this.options,
                        forecastDataPoints: { count: data.forecastDataPoints },
                        xaxis: { ...this.options.xaxis, categories: data.categories }
                    }
                })
                .catch(() => this.$toast.warning('Erro ao buscar o fluxo de caixa.'))
                .finally(() => (this.loading = false))
        },
        changeSelectedBankAccounts() {}
    },
    watch: {
        period() {
            this.getCashFlowChart()
        },
        bankAccountIds() {
            this.getCashFlowChart()
        }
    }
  }
  </script>
  <style lang="scss" scoped>
    .card {
      display: flex;
      height: auto;
      width: 100%;
      border-radius: 8px;
      text-align: left;
      justify-content: center;
      border: 1px solid var(--neutral-200);
      .cash-flow-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        gap: 20px;

        .cash-flow-title {
            font-family: "Red Hat Display";
            font-weight: 500;
            font-size: 16px;
            color: var(--dark-blue);
        }
        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;

            .select-box {
                width: 350px;
                .form-group {
                    margin-bottom: 0;
                }
            }
        }
      }
      .chart {
        .header {
          display: flex;
          flex-direction: row;
          padding: 20px 20px 0 20px;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          color: var(--dark-blue);
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  
  </style>
  